import { useFallbackRef } from '@diallink-corp/convergo-aria-utils';
import { SlotsProvider } from '@diallink-corp/convergo-react-layout';
import { StyleProps } from '@diallink-corp/convergo-types';
import { LinksFunction } from 'react-router';
import clsx from 'clsx';
import { forwardRef, MutableRefObject, ReactNode, useMemo } from 'react';

import styles from './DetailLayout.css?url';

export interface DetailLayoutProps extends StyleProps {
  /** The content of the layout. */
  children: ReactNode;
}

export const links: LinksFunction = () => {
  return [{ rel: 'stylesheet', href: styles }];
};

/**
 * A layout component for detail style pages with a header,
 * an avatar, the name of an entity and several sections.
 */
export const DetailLayout = forwardRef(function DetailLayout(
  props: DetailLayoutProps,
  ref: MutableRefObject<HTMLDivElement>
) {
  const { children, className, ...otherProps } = props;

  ref = useFallbackRef(ref);

  const slots = useMemo(
    () => ({
      toolbar: { className: 'DetailLayout-toolbar' },
      header: { className: 'DetailLayout-header' },
      avatar: { size: 'xlarge', className: 'DetailLayout-avatar' },
      details: { className: 'DetailLayout-details' },
      heading: { className: 'DetailLayout-heading' },
      description: { className: 'DetailLayout-description' },
      buttonGroup: { className: 'DetailLayout-buttonGroup' },
      content: { className: 'DetailLayout-content' },
      section: { className: 'DetailLayout-section' },
      descriptionList: { className: 'DetailLayout-descriptionList' }
    }),
    []
  );

  // TODO: Implement mobile responsive layout.

  return (
    <div {...otherProps} className={clsx('DetailLayout', className)} ref={ref}>
      <SlotsProvider slots={slots}>{children}</SlotsProvider>
    </div>
  );
});
