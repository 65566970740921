import convergoStylesheetUrl from '@diallink-corp/convergo-css/dist/index.css?url';
import { ConvergoProvider } from '@diallink-corp/convergo-react-provider';
import { ToastContainer } from '@diallink-corp/convergo-react-toast';
import {
  ColorScheme as ConvergoColorScheme,
  Scale as ConvergoScale
} from '@diallink-corp/convergo-types';
import * as Sentry from '@sentry/react';
import clsx from 'clsx';
import { PropsWithChildren, ReactNode, use, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  isRouteErrorResponse,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useNavigate
} from 'react-router';

import type { Route } from '+/+types/root';
import { links as detailLayoutLinks } from '~/lib/components/Layout/DetailLayout';
import { links as listLayoutLinks } from '~/lib/components/Layout/ListLayout';
import { links as listViewLayoutLinks } from '~/lib/components/Layout/ListViewLayout';
import { links as sidebarLayoutLinks } from '~/lib/components/Layout/SidebarLayout';
import { Assets } from '~/lib/pwa';
import { theme } from '~/lib/styles/theme';
import { DialLinkHandle } from '~/lib/types/DialLinkHandle';
import { ColorScheme, Scale } from '~/services/hub/models/User';
import globalStylesheetUrl from '~/styles/global.css?url';

import { IllustratedBoundary } from './lib/components/ErrorBoundary/IllustratedBoundary';
import { FlashPrevention } from './lib/components/FlashPrevention/FlashPrevention';
import { GoogleTagManagerScript } from './lib/components/GoogleTagManager/GoogleTagManagerScript';
import { ServerHealthMonitor } from './lib/components/ServerHealthMonitor/ServerHealthMonitor';
import {
  ElectronContext,
  ElectronProvider
} from './lib/contexts/ElectronContext';
import { NetworkInformationProvider } from './lib/contexts/NetworkInformationContext';
import {
  PreferencesContext,
  PreferencesProvider,
  PreferencesStorage
} from './lib/contexts/PreferencesContext';
import { RevalidatorProvider } from './lib/contexts/RevalidatorContext';
import callPopoverStylesheetUrl from './soft-phone/call-popover.css?url';
import electronStylesheetUrl from './styles/electron.css?url';
import tailwindStylesheetUrl from './styles/tailwind.css?url';

// All routes inherit this configuration.
// Routes can overrides the config.
export const config = {
  maxDuration: 300 // 5 minutes
};

export const handle = {
  id: 'root',
  i18n: ['common']
} satisfies DialLinkHandle;

export function meta() {
  return [{ title: 'DialLink' }];
}

export const links: Route.LinksFunction = () => {
  return [
    { rel: 'stylesheet', href: convergoStylesheetUrl },
    { rel: 'stylesheet', href: globalStylesheetUrl },
    { rel: 'stylesheet', href: tailwindStylesheetUrl },
    { rel: 'stylesheet', href: callPopoverStylesheetUrl },
    ...detailLayoutLinks(),
    ...listLayoutLinks(),
    ...sidebarLayoutLinks(),
    ...listViewLayoutLinks(),
    import.meta.env.VITE_PLATFORM === 'DESKTOP'
      ? { rel: 'stylesheet', href: electronStylesheetUrl }
      : undefined,
    {
      rel: 'icon',
      sizes: '16x16',
      type: 'image/x-icon',
      href: '/favicons/16x16-light.ico',
      media: '(prefers-color-scheme: light)'
    },
    {
      rel: 'icon',
      sizes: '16x16',
      type: 'image/x-icon',
      href: '/favicons/16x16-dark.ico',
      media: '(prefers-color-scheme: dark)'
    },
    {
      rel: 'icon',
      sizes: '32x32',
      type: 'image/x-icon',
      href: '/favicons/32x32-light.ico',
      media: '(prefers-color-scheme: light)'
    },
    {
      rel: 'icon',
      sizes: '32x32',
      type: 'image/x-icon',
      href: '/favicons/32x32-dark.ico',
      media: '(prefers-color-scheme: dark)'
    },
    {
      rel: 'icon',
      sizes: '96x96',
      type: 'image/x-icon',
      href: '/favicons/96x96-light.ico',
      media: '(prefers-color-scheme: light)'
    },
    {
      rel: 'icon',
      sizes: '96x96',
      type: 'image/x-icon',
      href: '/favicons/96x96-dark.ico',
      media: '(prefers-color-scheme: dark)'
    },
    {
      rel: 'icon',
      sizes: '180x180',
      type: 'image/x-icon',
      href: '/favicons/180x180-light.ico',
      media: '(prefers-color-scheme: light)'
    },
    {
      rel: 'icon',
      sizes: '180x180',
      type: 'image/x-icon',
      href: '/favicons/180x180-dark.ico',
      media: '(prefers-color-scheme: dark)'
    }
  ].filter(Boolean) as Route.LinkDescriptors;
};

type Platform = 'web' | 'darwin' | 'win32';

export function loader() {
  return null;
}

export function clientLoader() {
  return {
    project: 'DialLink',
    platform: process.platform as Platform,
    user: {
      scale: Scale.MEDIUM,
      colorScheme: ColorScheme.SYSTEM
    }
  };
}

export function HydrateFallback() {
  return (
    <nav className="h-full bg-[url('/images/hydrate-background.png')] bg-cover bg-center flex justify-center items-center drag">
      <span className="bg-[url('/images/hydrate-spinner.svg')] h-[20vh] w-[20vw] block bg-no-repeat" />
    </nav>
  );
}

export default function Root() {
  return <Outlet />;
}

export function ErrorBoundary({ error }: Route.ErrorBoundaryProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  console.error('[Root][ErrorBoundary][Error]', error);

  useEffect(() => {
    Sentry.captureException(error);
  }, [error, navigate]);

  return isRouteErrorResponse(error) && error.status === 404 ? (
    <IllustratedBoundary
      illustrationSrc="/images/error-boundary-illustration.svg"
      illustrationAlt={t('page-not-found')}
      heading={t('page-not-found')}
      subheading={t('page-does-not-seem-to-exist')}
    />
  ) : (
    <IllustratedBoundary
      illustrationSrc="/images/error-boundary-illustration.svg"
      illustrationAlt={t('something-went-wrong')}
      heading={t('something-went-wrong')}
      subheading={t('we-are-working-on-this')}
      description={t('please-try-again-later-or-contact-support')}
    />
  );
}

interface LayoutProps {
  /** The content of the document. */
  children: ReactNode;
}

export function Layout(props: LayoutProps) {
  const { children } = props;

  const localPreferencesStorage = useMemo(
    () => new LocalPreferencesStorage(),
    []
  );

  return (
    <PreferencesProvider storage={localPreferencesStorage}>
      <ElectronProvider>
        <Html>
          <head>
            <meta charSet="utf-8" />
            <meta
              name="viewport"
              content="width=device-width,initial-scale=1"
            />
            <Meta />
            <Assets />
            <Links />
            <FlashPrevention />
          </head>
          <Body>
            <NetworkInformationProvider>
              <RevalidatorProvider>
                {children}
                <ToastContainer />
              </RevalidatorProvider>
            </NetworkInformationProvider>
            <ServerHealthMonitor />
            <ScrollRestoration />
            <Scripts />
          </Body>
        </Html>
      </ElectronProvider>
    </PreferencesProvider>
  );
}

function Html(props: PropsWithChildren) {
  const { i18n } = useTranslation();

  const { children } = props;

  const { fullscreen, focused, port } = use(ElectronContext);
  const [preferences] = use(PreferencesContext);

  const scale = getScale(preferences.scale);
  const colorScheme = getColorScheme(preferences.colorScheme);

  useEffect(() => {
    port?.postMessage({
      changeTheme:
        preferences.colorScheme === ColorScheme.SYSTEM ? 'system' : colorScheme
    });
  }, [colorScheme, port, preferences.colorScheme]);

  return (
    <html
      data-platform={process.platform}
      data-fullscreen={fullscreen}
      suppressHydrationWarning
      lang={i18n.resolvedLanguage}
      dir={i18n.dir()}
      style={colorScheme && { colorScheme }}
      className={clsx(
        'convergo',
        scale && `convergo--${scale}`,
        colorScheme && `convergo--${colorScheme}`,
        focused && 'is-focused'
      )}
    >
      {children}
    </html>
  );
}

function Body(props: PropsWithChildren) {
  const { i18n } = useTranslation();

  const { children } = props;

  const [preferences] = use(PreferencesContext);

  const scale = getScale(preferences.scale);
  const colorScheme = getColorScheme(preferences.colorScheme);

  return (
    <body suppressHydrationWarning>
      <GoogleTagManagerScript />
      <ConvergoProvider
        theme={theme}
        scale={scale}
        colorScheme={colorScheme}
        locale={i18n.resolvedLanguage}
        className="ConvergoProvider"
      >
        {children}
      </ConvergoProvider>
    </body>
  );
}

/**
 * A utility method to get the scale for the Convergo Provider.
 * @param scale The scale set by the user.
 * @returns The Convergo scale.
 */
function getScale(scale: Scale): ConvergoScale {
  switch (scale) {
    case Scale.SMALL:
      return 'small';
    case Scale.MEDIUM:
      return 'medium';
    case Scale.LARGE:
      return 'large';
    default:
      throw new Error(`Invalid scale: ${scale}`);
  }
}

/**
 * A utility method to get the color scheme for the Convergo Provider.
 * @param colorScheme The color scheme set by the user.
 * @returns The Convergo color scheme.
 */
function getColorScheme(
  colorScheme: ColorScheme
): ConvergoColorScheme | undefined {
  // If the color scheme is undefined, Convergo will use the system color scheme.
  if (colorScheme === ColorScheme.SYSTEM) {
    if (!import.meta.env.SSR) {
      return window.matchMedia('(prefers-color-scheme: dark)').matches
        ? 'dark'
        : 'light';
    }
    return undefined;
  }

  switch (colorScheme) {
    case ColorScheme.LIGHT:
      return 'light';
    case ColorScheme.DARK:
      return 'dark';
    default:
      throw new Error(`Invalid color scheme: ${colorScheme}`);
  }
}

class LocalPreferencesStorage implements PreferencesStorage {
  get(key: string): string | null {
    try {
      return localStorage.getItem(key);
    } catch (error) {
      return null;
    }
  }
  set(key: string, value: string): void {
    localStorage.setItem(key, value);
  }
}
