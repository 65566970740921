import { SlotsProvider } from '@diallink-corp/convergo-react-layout';
import { StyleProps } from '@diallink-corp/convergo-types';
import { LinksFunction } from 'react-router';
import clsx from 'clsx';
import { ReactNode, useMemo } from 'react';

import styles from './ListViewLayout.css?url';

export interface ListViewLayoutProps extends StyleProps {
  /** The content of the layout. */
  children: ReactNode;
}

export const links: LinksFunction = () => {
  return [{ rel: 'stylesheet', href: styles }];
};

/**
 * A layout component featuring a list and an aside detail area.
 */
export function ListViewLayout(props: ListViewLayoutProps) {
  const { children, className, ...otherProps } = props;

  const slots = useMemo(
    () => ({
      header: { className: 'ListViewLayout-header' },
      toolbar: { className: 'ListViewLayout-toolbar' },
      searchField: { className: 'ListViewLayout-searchField' },
      listView: { className: 'ListViewLayout-listView' },
      markAsReadToggleButton: { className: 'ListViewLayout-readToggle' },
      details: { className: 'ListViewLayout-details' },
      actionGroup: { className: 'ListViewLayout-actionGroup' }
    }),
    []
  );

  // TODO: Implement mobile responsive layout

  return (
    <div {...otherProps} className={clsx('ListViewLayout', className)}>
      <SlotsProvider slots={slots}>{children}</SlotsProvider>
    </div>
  );
}
