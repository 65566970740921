import { SlotsProvider } from '@diallink-corp/convergo-react-layout';
import { StyleProps } from '@diallink-corp/convergo-types';
import { LinksFunction } from 'react-router';
import clsx from 'clsx';
import { ReactNode, useMemo } from 'react';

import styles from './ListLayout.css?url';

export interface ListLayoutProps extends StyleProps {
  /** The content of the layout. */
  children: ReactNode;
}

export const links: LinksFunction = () => {
  return [{ rel: 'stylesheet', href: styles }];
};

/**
 * A layout component featuring a list and an aside detail area.
 */
export function ListLayout(props: ListLayoutProps) {
  const { children, className, ...otherProps } = props;

  const slots = useMemo(
    () => ({
      header: { className: 'ListLayout-header' },
      toolbar: { className: 'ListLayout-toolbar' },
      panel: { className: 'ListLayout-panel' },
      searchField: { className: 'ListLayout-searchField' },
      details: { className: 'ListLayout-details' },
      placeholder: { className: 'ListLayout-placeholder' },
      resizablePanels: {
        minWidth: 320,
        defaultWidth: 370,
        maxWidth: 600,
        className: 'ListLayout-resizablePanels'
      },
      resizer: { className: 'ListLayout-resizer' }
    }),
    []
  );

  // TODO: Implement mobile responsive layout

  return (
    <div {...otherProps} className={clsx('ListLayout', className)}>
      <SlotsProvider slots={slots}>{children}</SlotsProvider>
    </div>
  );
}
