import acceptInvite from './en_US/accept-invite.json';
import apiKeys from './en_US/api-keys.json';
import audioLibrary from './en_US/audio-library.json';
import auth from './en_US/auth.json';
import autoReceptionists from './en_US/auto-receptionists.json';
import autoUpdater from './en_US/auto-updater.json';
import billing from './en_US/billing.json';
import callQueues from './en_US/call-queues.json';
import calls from './en_US/calls.json';
import common from './en_US/common.json';
import company from './en_US/company.json';
import contacts from './en_US/contacts.json';
import countries from './en_US/countries.json';
import enums from './en_US/enums.json';
import errors from './en_US/errors.json';
import general from './en_US/general.json';
import messages from './en_US/messages.json';
import navigation from './en_US/navigation.json';
import onboarding from './en_US/onboarding.json';
import phoneNumbers from './en_US/phone-numbers.json';
import preferences from './en_US/preferences.json';
import profile from './en_US/profile.json';
import ringGroups from './en_US/ring-groups.json';
import security from './en_US/security.json';
import settings from './en_US/settings.json';
import subscriptionsWarning from './en_US/subscriptions-warning.json';
import subscriptions from './en_US/subscriptions.json';
import trustRegistration from './en_US/trust-registration.json';
import trust from './en_US/trust.json';
import users from './en_US/users.json';
import voicemails from './en_US/voicemails.json';
import welcome from './en_US/welcome.json';
import zod from './en_US/zod.json';

export default {
  'accept-invite': acceptInvite,
  'api-keys': apiKeys,
  'audio-library': audioLibrary,
  auth: auth,
  'auto-receptionists': autoReceptionists,
  'auto-updater': autoUpdater,
  billing: billing,
  'call-queues': callQueues,
  calls: calls,
  common: common,
  company: company,
  contacts: contacts,
  countries: countries,
  enums: enums,
  errors: errors,
  general: general,
  messages: messages,
  navigation: navigation,
  onboarding: onboarding,
  'phone-numbers': phoneNumbers,
  preferences: preferences,
  profile: profile,
  'ring-groups': ringGroups,
  security: security,
  settings: settings,
  'subscriptions-warning': subscriptionsWarning,
  subscriptions: subscriptions,
  trust: trust,
  'trust-registration': trustRegistration,
  users: users,
  voicemails: voicemails,
  welcome: welcome,
  zod
};
