import { SlotsProvider } from '@diallink-corp/convergo-react-layout';
import { StyleProps } from '@diallink-corp/convergo-types';
import { LinksFunction } from 'react-router';
import clsx from 'clsx';
import { ReactNode, useMemo } from 'react';

import styles from './SidebarLayout.css?url';

export interface SidebarLayoutProps extends StyleProps {
  /** The content of the layout. */
  children: [ReactNode, ReactNode];
}

export const links: LinksFunction = () => {
  return [{ rel: 'stylesheet', href: styles }];
};

/**
 * A layout component featuring a sidebar as well as a main content area.
 */
export function SidebarLayout(props: SidebarLayoutProps) {
  const { children, className, ...otherProps } = props;

  const slots = useMemo(
    () => ({
      aside: { className: 'SidebarLayout-aside' },
      sideNavigation: { className: 'SidebarLayout-sideNavigation' },
      content: { className: 'SidebarLayout-content' },
      header: { className: 'SidebarLayout-header' },
      toolbar: { className: 'SidebarLayout-toolbar' }
    }),
    []
  );

  const [aside, content] = children;
  return (
    <div {...otherProps} className={clsx('SidebarLayout', 'h-full', className)}>
      <SlotsProvider slots={slots}>
        {aside}
        <div className="SidebarLayout-wrapper">{content}</div>
      </SlotsProvider>
    </div>
  );
}
